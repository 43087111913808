import React, {useState, Component} from 'react';
import classNames from 'classnames';
import ReactApexChart from 'react-apexcharts';
import 'apexcharts/dist/apexcharts.css';
import {
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  FormControl,
  withStyles,
  Tooltip,
  Icon,
} from '@material-ui/core';
import {contractService} from '~/services';
import moment from 'moment';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from 'material-ui-pickers';
import _ from 'lodash';
import {
  privacyCardStyles, mostBookedCardStyles, topVisitedUnitAnalyticsStyles, emptyGraphStyles
} from './styles';
import { FeatureFlagsContext } from '~/contexts/FeatureFlagsContext';

const PieChart = ({labels, data}) => {
  if (data.length < 1) {
    return <EmptyDataAlert />;
  }
  const [options] = useState({
    labels,
    stroke: {
      colors: ['#FFF'] // hidden border
    },
    colors: ['#F96E53', '#3A10E0', '#FFC300', '#6CD0E4', '#22B9D7', '#F07C09'],
    chart: {
      type: 'donut',
      labels: false,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    }
  });

  return (
    <Grid>
      <ReactApexChart
        options={options}
        series={data}
        type="donut"
        width={380}
            />
    </Grid>
  );
};

const MostBookedSpecialtiesChart = ({labels, data}) => {

  const [options] = useState({
    chart: {
      toolbar: false,
    },
    colors: [
      function ({dataPointIndex}) {
        switch (dataPointIndex) {
          case 0:
            return '#3A10E0';
          case 1:
            return '#22B9D7';
          case 2:
            return '#F0BA09';
          case 3:
            return '#8C22D7';
          case 4:
            return '#F07C09';
          default:
            return '#3A10E0';
        }
      }
    ],
    plotOptions: {
      bar: {
        columnWidth: '30%',
        borderRadius: 3,
        borderRadiusApplication: 'around',
        borderRadiusWhenStacked: 'all',
        horizontal: false,
      }
    },
    xaxis: {
      categories: labels,
      labels: {
        style: {
          fontSize: '11px'
        },
        formatter: value => _.capitalize(value)
      }
    },
  });

  const [series] = useState([{
    data,
    name: 'Valor',
  }]);

  return (
    <Grid>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={216}
            />
    </Grid>
  );
};

const MostBookedSpecialtiesPrivacyCard = ({ classes }) => (
  <Card className={classes.root}>
    <CardContent className={classes.content}>
      <span className={`material-icons ${classes.materialIcons}`}>info</span>
      <Typography className={classes.title}>
            Para conferir esse dado, é necessário que pelo menos 3 pessoas de cada gênero na sua equipe tenham realizado exames no
            período analisado
      </Typography>
      <Typography className={classes.description} component="p">
            Para preservar a privacidade dos seus colaboradores, o dr.consulta só poderá exibir dados referentes ao
            agendamento de exames quando 3 pessoas de cada gênero tiverem realizado exames no período analisado.
      </Typography>
      {/* <CardActions className={classes.actions}>
            <DrcHeartCheckIcon/>
            <a href="javascript:void(0)">Veja como engajar seus colaboradores a cuidar da saúde</a>
          </CardActions> */}
    </CardContent>
  </Card>
);

const StyledMostBookedSpecialtiesPrivacyCard = withStyles(privacyCardStyles)(MostBookedSpecialtiesPrivacyCard);

const currencyFormat = value => new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
}).format(value || 0);

const qtyFormat = value => new Intl.NumberFormat('pt-BR', {}).format(Number(value));

const EmptyDataAlert = withStyles(emptyGraphStyles)((props) => {
  const { classes } = props;
  return (
    <div className={classes.alert}>
      <Icon className={classes.alertIcon}>bar_chart</Icon>
      <Typography className={classes.alertText} variant="body1">Sem serviços no período</Typography>
    </div>
  );
});

const TopVisitedUnitAnalytics = withStyles(topVisitedUnitAnalyticsStyles)((props) => {
  const { classes, data } = props;
  const hasData = data && data.length > 0;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        { !hasData && (
          <EmptyDataAlert />
        )}
        { hasData && data.map((unit, index) => (
          <div key={index} className={classNames(classes.bar, classes[`bar${unit.position}`])}>
            <Tooltip title={unit.hospital} placement="top" classes={{ tooltip: classes.tooltip }}>
              <Typography className={classNames(classes.title, classes.ellipsis)} variant="body1">{`${index + 1}˚ | ${unit.hospital}`}</Typography>
            </Tooltip>
            <Typography className={classes.value} variant="body1">{unit.quantity}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
});

class HealthComponent extends Component {
  static contextType = FeatureFlagsContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      periodSelectionFilter: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
      totalPeople: 0,
      totalAccountsActivePreviousMonth: 0,
      totalServices: 0,
      averageTicket: 0,
      economyGauge: 0,
      servicesProvidedChart: {
        labels: [],
        data: []
      },
      examsTakenChart: {
        labels: [],
        data: []
      },
      mostVisitedUnits: {
        data: []
      },
      mostBookedSpecialtiesChart: {
        labels: ['ViewBlocked', 'ViewBlocked', 'ViewBlocked'],
        data: [10, 20, 30]
      },
    };
  }

  handleDateChange(e) {
    this.setState({periodSelectionFilter: moment(e), loading: true}, () => {
      this.update(this.props.selectedContract);
    });
  }

  setLoading(status) {
    this.setState({loading: status});
  }

  async componentDidMount() {
    this.setState({loading: true}, () => {
      this.update(this.props.selectedContract);
    });
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.selectedContract.id !== nextProps.selectedContract.id) {
      await this.update(nextProps.selectedContract);
    }
  }

  resetState() {
    this.setState({
      totalPeople: 0,
      loading: false,
      totalAccountsActivePreviousMonth: 0,
      totalServices: 0,
      averageTicket: currencyFormat(0).split(','),
      economyGauge: currencyFormat(0).split(','),
      servicesProvidedChart: {
        labels: [],
        data: []
      },
      examsTakenChart: {
        labels: [],
        data: []
      },
      mostVisitedUnits: {
        data: []
      },
      mostBookedSpecialtiesChart: {
        labels: ['ViewBlocked', 'ViewBlocked', 'ViewBlocked'],
        data: [10, 20, 30]
      },
    });
  }

  async update(selectedContract) {
    this.setLoading(true);
    if (!selectedContract || !selectedContract.id) {
      this.resetState();
      return;
    }

    try {
      const { minDate, maxDate } = this.computeCalendarBounds(selectedContract);
      const { periodSelectionFilter } = this.state;
      let currentDateFilter = moment(periodSelectionFilter).startOf('month');

      if (currentDateFilter < minDate || currentDateFilter > maxDate) {
        this.setState({periodSelectionFilter: minDate.format('YYYY-MM-DD')});
        currentDateFilter = minDate;
      }

      const totalStatus = await contractService.totalStatus(selectedContract, currentDateFilter.format('YYYY-MM-DD'));

      if (totalStatus.data) {
        this.setState({
          totalPeople: totalStatus.data.totalPeople,
          totalAccountsActivePreviousMonth: totalStatus.data.totalAccountsActivePreviousMonth,
          totalServices: totalStatus.data.totalServices,
          economyGauge: currencyFormat(totalStatus.data.totalSaved).split(','),
          averageTicket: currencyFormat(totalStatus.data.totalAverageTicket).split(','),
          isShowMostBookedSpecialtiesChart: totalStatus.data.canShowMostBookedSpecialtiesChart,
        });

        this.totalServicesChart(totalStatus.data.totalServicesChart);
        this.getExamsTakenChart(totalStatus.data.totalExamsTakenChart);
        this.mostVisitedUnitsChart(totalStatus.data.totalMostVisitedUnits);
        this.getMostBookedSpecialtiesChart(totalStatus.data.medicalSpecialty);
      }

    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      this.resetState();
    } finally {
      this.setLoading(false);
    }
  }

  totalServicesChart(response) {
    const labels = [];
    const data = [];

    response.forEach((mfh) => {
      labels.push(_.startCase(mfh.service));
      data.push(Number(mfh.quantity));
    });

    this.setState({
      servicesProvidedChart: {
        labels,
        data
      }
    });
  }

  getExamsTakenChart(response) {
    const labels = [];
    const data = [];

    response.forEach((mfh) => {
      labels.push(_.startCase(mfh.exam));
      data.push(Number(mfh.quantity));
    });

    this.setState({
      examsTakenChart: {
        labels,
        data
      }
    });
  }

  mostVisitedUnitsChart(response) {
    let lastQuantity = 0;
    let position = 0;
    const data = response.map((item) => {
      position = (lastQuantity === item.quantity) ? position : position + 1;
      const rank = {
        hospital: _.startCase(item.hospital),
        quantity: item.quantity,
        position,
      };

      lastQuantity = item.quantity;
      return rank;
    });

    this.setState({
      mostVisitedUnits: {
        data,
      }
    });
  }

  getMostBookedSpecialtiesChart(response) {
    if (!response.length) return;

    const labels = [];
    const data = [];

    response.forEach((mfh) => {
      labels.push(_.startCase(
        mfh.product
          .replace(/\b(consulta)\s+/gi, '')
          .replace(/\b(teleconsulta)\s+/gi, 'TC ')
          .trim()
      ));
      data.push(Number(mfh.quantity));
    });

    this.setState({
      mostBookedSpecialtiesChart: {
        labels,
        data
      }
    });
  }

  computeCalendarBounds(selectedContract) {
    const start = selectedContract.signature_date ? selectedContract.signature_date.substring(0, 10) : '';
    const end = selectedContract.closure_date ? selectedContract.closure_date.substring(0, 10) : '';
    const minDate = moment(start).startOf('month');
    const maxDate = moment(end).endOf('month');
    return { minDate, maxDate };
  }

  render() {
    const { featureFlags } = this.context;

    const {
      totalPeople,
      totalAccountsActivePreviousMonth,
      totalServices,
      economyGauge,
      servicesProvidedChart,
      examsTakenChart,
      mostVisitedUnits,
      mostBookedSpecialtiesChart,
      loading,
      periodSelectionFilter,
      averageTicket,
      isShowMostBookedSpecialtiesChart,
    } = this.state;

    const { classes } = this.props;

    const { minDate, maxDate } = this.computeCalendarBounds(this.props.selectedContract);

    return (
      <React.Fragment>

        {!featureFlags.MENU_HEALTH_PANEL && (
        <Grid container justify="center" style={{padding: 100}}>
          <Typography variant="h1" color="textPrimary">Página indisponível.</Typography>
        </Grid>
        )}

        {!this.props.selectedContract.contracting_company_cnpj && featureFlags.MENU_HEALTH_PANEL && (
        <Grid container justify="center" style={{padding: 100}}>
          <Typography variant="h1" color="textPrimary">Selecione um contrato para começar.</Typography>
        </Grid>
        )}

        {loading && (
        <Grid container justify="center" style={{padding: 100}}>
          <CircularProgress style={{margin: 50, color: '#005DF9'}} />
        </Grid>
        )}

        {this.props.selectedContract.contracting_company_cnpj && !loading && featureFlags.MENU_HEALTH_PANEL && (
        <div className="app-wrapper">
          <FormControl fullWidth margin="normal">
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
              <div className="pickers">
                <DatePicker
                  views={['month', 'year']}
                  label="Mês e ano"
                  minDate={minDate}
                  maxDate={maxDate}
                  value={periodSelectionFilter}
                  onChange={e => this.handleDateChange(e)}
                                    />
              </div>
            </MuiPickersUtilsProvider>
          </FormControl>
          <Grid container direction="column" alignItems="center" justify="flex-start">
            <Grid item container style={{backgroundColor: 'transparent', maxWidth: '916px', margin: '0px auto', }}>
              <Grid
                style={{
                  marginBottom: 24, display: 'flex', width: '100%', justifyContent: 'space-between'
                }}>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: 'flex', textAlign: 'left', marginRight: 8, justifyContent: 'flex-start'
                  }}>
                  <Paper style={{borderRadius: '24px', padding: 16, width: '100%'}}>
                    <Typography variant="h4" color="textPrimary"> Total de pessoas </Typography>
                    <p style={{
                      fontSize: 34,
                      fontWeight: 700,
                      fontFamily: 'Sua dr consulta',
                      color: '#1A1A1A',
                      textAlign: 'left',
                    }}>
                      {totalPeople}
                      {' '}
pessoas
                    </p>

                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                      {totalAccountsActivePreviousMonth}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid
                  item
                  xs={4}
                  style={{
                    display: 'flex', textAlign: 'left', justifyContent: 'flex-start', marginRight: 8
                  }}>
                  <Paper style={{borderRadius: '24px', padding: 16, width: '100%'}}>
                    <Typography variant="h4" color="textPrimary"> Total de serviços </Typography>
                    <p style={{
                      fontSize: 34,
                      fontWeight: 700,
                      fontFamily: 'Sua dr consulta',
                      color: '#1A1A1A',
                      textAlign: 'left'
                    }}>
                      {qtyFormat(totalServices)}
                    </p>
                    <Typography variant="body1"> Atendimentos dentro do dr.consulta </Typography>
                  </Paper>
                </Grid>

                <Grid
                  item
                  xs={4}
                  style={{
                    display: 'flex', textAlign: 'left', justifyContent: 'flex-start', marginRight: 8
                  }}>
                  <Paper style={{borderRadius: '24px', padding: 16, width: '100%'}}>
                    <Typography variant="h4" color="textPrimary" style={{ textAlign: 'left' }}> Ticket médio </Typography>
                    <Typography variant="body1" style={{ textAlign: 'left' }}> Seus colaboradores dedicaram </Typography>
                    <p style={{
                      fontSize: 34,
                      fontWeight: 700,
                      fontFamily: 'Sua dr consulta',
                      color: '#1A1A1A',
                      textAlign: 'left',
                    }}>
                      {averageTicket[0]}
,
                      <span style={{fontSize: '24px'}}>{averageTicket[1]}</span>
                    </p>
                    <Typography variant="body1" style={{ textAlign: 'left' }}> no benefício de saúde </Typography>
                  </Paper>
                </Grid>

                <Grid
                  item
                  xs={4}
                  style={{display: 'flex', textAlign: 'left', justifyContent: 'flex-start'}}>
                  <Paper style={{borderRadius: '24px', padding: 16, width: '100%'}}>
                    <Typography variant="h4" color="textPrimary" style={{ textAlign: 'left' }}> Economizômetro </Typography>
                    <Typography variant="body1" style={{ textAlign: 'left' }}> A economia do seu time foi de </Typography>
                    <p style={{
                      fontSize: 34,
                      fontWeight: 700,
                      fontFamily: 'Sua dr consulta',
                      color: '#1A1A1A',
                      textAlign: 'left',
                    }}>
                      {economyGauge[0]}
,
                      <span style={{fontSize: '24px'}}>{economyGauge[1]}</span>
                    </p>
                  </Paper>
                </Grid>
              </Grid>

              <div style={{
                display: 'flex', justifyContent: 'space-between', marginBottom: 24, width: '100%'
              }}>
                <div style={{display: 'flex', width: '100%'}}>
                  <Grid
                    item
                    xs={5}
                    style={{
                      display: 'flex', justifyContent: 'center', marginRight: 8
                    }}>
                    <Paper style={{borderRadius: '24px', padding: 16, width: '100%'}}>
                      <Typography variant="h4" color="textPrimary">Atendimentos realizados</Typography>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignItems: 'center',
                        width: '100%'
                      }}>
                        <PieChart labels={servicesProvidedChart.labels} data={servicesProvidedChart.data} />
                      </div>
                    </Paper>
                  </Grid>

                  <Grid
                    item
                    xs={7}
                    style={{
                      display: 'flex', justifyContent: 'center', minWidth: '285px'
                    }}>
                    <Paper style={{borderRadius: '24px', padding: 16, width: '100%'}}>
                      <Typography variant="h4" color="textPrimary" style={{ marginBottom: 12 }}>Top 5 de unidades mais frequentadas</Typography>
                      <div style={{maxWidth: '890px'}}>
                        <TopVisitedUnitAnalytics data={mostVisitedUnits.data} />
                      </div>
                    </Paper>
                  </Grid>
                </div>
              </div>

              <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', }}>
                <div xs={12} style={{display: 'flex', width: '100%'}}>
                  <Grid
                    item
                    xs={7}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: 'auto',
                      minWidth: '285px',
                      marginRight: 8
                    }}>
                    <Paper style={{borderRadius: '24px', padding: 16, width: '100%'}}>
                      <Typography variant="h4" color="textPrimary">Especialidades mais agendadas</Typography>
                      <div className={classes.root}>
                        <Icon className={classNames({
                          [classes.materialIcons]: !isShowMostBookedSpecialtiesChart,
                          [classes.hiddenLockedIcon]: isShowMostBookedSpecialtiesChart,
                        })}
                      >
lock
                        </Icon>
                        <div className={classNames({[classes.locked]: !isShowMostBookedSpecialtiesChart, })}>
                          <MostBookedSpecialtiesChart
                            labels={mostBookedSpecialtiesChart.labels}
                            data={mostBookedSpecialtiesChart.data} />
                        </div>
                      </div>
                      {!isShowMostBookedSpecialtiesChart && (
                        <StyledMostBookedSpecialtiesPrivacyCard />
                      )}
                    </Paper>
                  </Grid>

                  <Grid
                    item
                    xs={5}
                    style={{
                      display: 'flex', justifyContent: 'center', height: 256, marginRight: 8
                    }}>
                    <Paper style={{borderRadius: '24px', padding: 16, width: '100%'}}>
                      <Typography variant="h4" color="textPrimary"> Exames realizados </Typography>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignItems: 'center',
                        width: '100%'
                      }}>
                        <PieChart
                          labels={examsTakenChart.labels}
                          data={examsTakenChart.data}
                                              />
                      </div>
                    </Paper>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({contract}) => {
  const {selectedContract} = contract;
  return {selectedContract};
};

export default connect(mapStateToProps, {})(withRouter((withStyles(mostBookedCardStyles()))(HealthComponent)));
